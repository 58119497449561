@import url('https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@400;500;600;700&family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-dark-color-font;
}

body {
  font-family: "Cabin";
  background: #f1f1f1;
}

h1,h2,h3,h4 {
  font-weight: 700;
}

.App {
  box-sizing: border-box;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.line-break {
  white-space: pre-wrap;
}


.videoRatio {
  overflow: hidden;
  padding: 56.25% 0 0 0;
  position: relative;
  width: 100%;
}

.videoInner {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.thumbnailButton {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playIcon {
  height: 42px;
  left: calc(50% - 30px);
  position: absolute;
  top: calc(50% - 21px);
  transition: all 0.3s ease-in-out;
  width: 60px;
}









@-webkit-keyframes scaleIn {
  to {
    transform: scale(1);
  }
}
@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes slideIn {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.play-toggle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.play-toggle .play-circle, .play-toggle .play-circle:before, .play-toggle .play-circle:after, .play-toggle span {
  display: inline-block;
  vertical-align: middle;
}
.play-toggle span {
  color: white;
  margin-left: 1rem;
  font-size: 1.2rem;
  transition: transform 350ms ease;
}
.play-toggle span i, .play-toggle span strong {
  display: inline-block;
  opacity: 0;
  transform: translate3d(-1rem, 0, 0);
  -webkit-animation: slideIn 400ms ease forwards;
          animation: slideIn 400ms ease forwards;
}
.play-toggle span i {
  color: rgba(255, 255, 255, 0.7);
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.play-toggle span strong {
  font-size: 1.25em;
  font-weight: 900;
  letter-spacing: 1px;
  -webkit-animation-delay: 350ms;
          animation-delay: 350ms;
}
.play-toggle .play-circle, .play-toggle .play-circle:before, .play-toggle .play-circle:after {
  position: relative;
  height: 4rem;
  width: 4rem;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  -webkit-animation: scaleIn 500ms cubic-bezier(0.075, 0.82, 0.165, 1) 500ms forwards;
          animation: scaleIn 500ms cubic-bezier(0.075, 0.82, 0.165, 1) 500ms forwards;
}
.play-toggle .play-circle:before, .play-toggle .play-circle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 0.04;
}
.play-toggle .play-circle:before {
  height: 8rem;
  width: 8rem;
  margin-left: -4rem;
  margin-top: -4rem;
}
.play-toggle .play-circle:after {
  height: 12rem;
  width: 12rem;
  margin-left: -6rem;
  margin-top: -6rem;
}
.play-toggle .play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  border-radius: 50%;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.3);
  transition: transform 350ms ease;
}
.play-toggle .play-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid #0274FA;
  border-bottom: 10px solid transparent;
}
.play-toggle:hover .play-icon {
  transform: scale(1.1);
}
.play-toggle:hover span {
  transform: translateX(0.3rem);
}